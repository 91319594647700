<template>
  <b-modal
      id="form-article-order-manual-modal"
      ref="show-form-article-order-manual-modal"
      title="Manuális sorrend állításs"
      ok-title="Mentés"
      cancel-title="Mégsem"
      :ok-disabled="isDisabled || waiting"
      cancel-variant="outline-secondary"
      ok-variant="success"
      @show="initModal"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <b-row class="mb-2">
      <b-col sm="12">
<!--        <strong><h5>Rövid ismertető a használattal kapcsolatban ...</h5></strong><br>-->
        Azonosító: melyik írást; Cél azonosító: melyik írás; Típus: elé / fölé
      </b-col>
    </b-row>
    <validation-observer ref="rules">
      <b-form
          ref="form"
          @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <b-col sm="12">
            <b-form-group
                label="Azonosító"
            >
              <validation-provider
                  #default="{ errors }"
                  name="azonosító"
                  rules="required"
                  vid="article_id"
              >
                <b-form-input
                    v-model="formData.article_id"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
                label="Cél azonosító"
            >
              <validation-provider
                  #default="{ errors }"
                  name="cél azonosító"
                  rules="required"
                  vid="target_id"
              >
                <b-form-input
                    v-model="formData.target_id"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
                label="Mozgatás típusa"
            >
              <validation-provider
                  #default="{ errors }"
                  name="mozgataás típusa"
                  rules="required"
                  vid="type"
              >
                <v-select
                    v-model="formData.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="typeOptions"
                    :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    vSelect,
  },
  data() {
    return {
      formData: {
        article_id: '',
        target_id: '',
        type: '',
      },
      waiting: false,
      typeOptions: [
        { title: 'Fölé', value: 'before' },
        { title: 'Alá', value: 'after' },
      ],
      required,
    }
  },
  computed: {
    isDisabled() {
      return !this.formData.article_id.length || !this.formData.target_id.length
    },
  },
  methods: {
    initModal() {
      // reset form data
      this.resetForm()
      // init first valu
      this.formData.type = this.typeOptions[0]
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.waiting = true

          let fData = JSON.parse(JSON.stringify(this.formData))
          fData.type = this.formData.type.value

          this.$store.dispatch('manualOrderArticle', fData).then(() => {
            this.$helpers.showSuccessToast()
            this.$nextTick(() => {
              this.$refs['show-form-article-order-manual-modal'].toggle('#toggle-btn')
            })
            this.$emit('refresh-table')
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.rules.setErrors(error.response.data.errors)
            } else if(error.response.status === 500){
                this.$helpers.showErrorToast(error.response.data.message)
            }else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.waiting = false
          })

        }
      })
    },
    handleCancel() {
      this.$refs['show-form-article-order-manual-modal'].toggle('#toggle-btn')
    },
    resetForm() {
      this.formData.article_id = ''
      this.formData.target_id = ''
      this.formData.type = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

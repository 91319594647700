<template>
    <div>
        <b-overlay
                :show="loading"
                spinner-variant="primary"
                spinner-type="grow"
                rounded="sm"
        >
            <b-row class="mb-3">
                <b-col sm="6">
                    <!--            <strong><h5>Rövid ismertető a használattal kapcsolatban ...</h5></strong><br>-->
                    A mozgatni kívánt soron lenyomva kell tartani az egér bal gombját és oda kell húzni a sort ahova
                    szeretnénk tenni.
                </b-col>
                <b-col sm="6">
                    <b-link
                            v-b-modal.form-article-order-manual-modal
                    >
                        <b-button
                                v-if="$acl.canEditPermission(role)"
                                size="sm"
                                variant="info"
                                class="ml-1 float-right"
                        >
                            Manuális sorrend
                        </b-button>
                    </b-link>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">

                    <b-row class="font-weight-bold mb-2" style="border: 1px solid grey;">
                        <b-col sm="1" class="p-1" style="border: 1px solid grey;">Azon.</b-col>
                        <b-col sm="4" class="p-1" style="border: 1px solid grey;">Cím</b-col>
                        <b-col sm="1" class="p-1" style="border: 1px solid grey;">Rank</b-col>
                        <b-col sm="1" class="p-1" style="border: 1px solid grey;">Megt. sz.</b-col>
                        <b-col sm="1" class="p-1" style="border: 1px solid grey;">Megj. kezd.</b-col>
                        <b-col sm="1" class="p-1" style="border: 1px solid grey;">Megj. vége.</b-col>
                        <b-col sm="2" class="p-1" style="border: 1px solid grey;">Lead</b-col>
                        <b-col sm="1" class="p-1" style="border: 1px solid grey;"/>
                    </b-row>

                    <draggable
                            v-model="rows"
                            class="list-group list-group-flush cursor-move"
                            @change="onDragged"
                    >

                        <transition-group
                                type="transition"
                                name="flip-list"
                        >

                            <b-col
                                    sm="12"
                                    v-for="listItem in rows"
                                    :key="listItem.id"
                                    class="dd-item mb-1 p-1"
                                    style="border: 1px solid grey;"
                            >
                                <b-row>
                                    <b-col sm="1">{{ listItem.id }}</b-col>
                                    <b-col sm="4">{{ listItem.title }}</b-col>
                                    <b-col sm="1">{{ listItem.sort_order }}</b-col>
                                    <b-col sm="1">{{ listItem.viewed }}</b-col>
                                    <b-col sm="1">{{ $helpers.replaceBlankText(listItem.visible_from) }}</b-col>
                                    <b-col sm="1">{{ $helpers.replaceBlankText(listItem.visible_to) }}</b-col>
                                    <b-col sm="2"><span v-html="$helpers.addPostfix(listItem.lead, 32)"></span></b-col>
                                    <b-col sm="1">
                                        <b-button
                                                v-if="$acl.canEditPermission(role)"
                                                size="sm"
                                                variant="info"
                                                class="ml-1"
                                                @click.stop="$router.push({name: 'modify-article', params: {id: listItem.id}})"
                                        >
                                            <feather-icon
                                                    icon="EditIcon"
                                            />
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </transition-group>
                    </draggable>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <b-pagination
                            v-model="page"
                            :total-rows="dataFromServer.total"
                            :per-page="dataFromServer.per_page"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mb-0"
                            @change="handleChangePage"
                    >
                        <template #prev-text>
                            <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                            />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </b-overlay>
        <manual-order-modal
                @refresh-table="initEntities"
        />
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import {
    BOverlay, BRow, BCol, BPagination, BButton, BLink,
} from 'bootstrap-vue'
import store from '@/store/index'
// modal
import manualOrderModal from "@/views/pages/_components/_modal/article/manualOrder.vue";

export default {
    components: {
        manualOrderModal,
        draggable,
        BOverlay,
        BRow,
        BCol,
        BPagination,
        BButton,
        BLink,
    },
    data() {
        return {
            loading: false,
            role: 'article',
            pageLength: 10,
            dir: false,
            pages: ['10', '20', '50'],
            rows: [],
            page: 1,
            dataFromServer: [],
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.initEntities()
    },
    methods: {
        initEntities() {
            this.showLoadBar()
            const searchParams = {
                page: parseInt(this.page),
                limit: parseInt(this.pageLength),
                sort: {
                    field: 'sort_order',
                    type: 'desc',
                }
            }

            this.$store.dispatch('fetchArticles', searchParams).then(response => {
                this.rows = response.data.entities.data
                this.dataFromServer = response.data.entities
                this.page = this.dataFromServer.current_page
                this.pageLength = parseInt(this.dataFromServer.per_page)
            }).finally(() => {
                this.hideLoadBar()
            })
        },
        handleChangePage(page) {
            this.page = page
            this.initEntities()
        },
        handlePageChange(active) {
            this.pageLength = active
            localStorage.setItem('pageLength', active)
            this.page = 1
            this.handleChangePage()
        },
        showLoadBar() {
            this.loading = true
        },
        hideLoadBar() {
            this.loading = false
        },
        onDragged() {
            this.showLoadBar()
            let newOrder = []

            Object.keys(this.rows).forEach(key => {
                newOrder.push(this.rows[key].id)
            })

            this.$store.dispatch('orderArticle', newOrder).then(() => {
                this.$helpers.showSuccessToast()
            }).catch(error => {
                if (error.response.status === 500) {
                    this.$helpers.showErrorToast(error.response.data.message)
                } else {
                    this.$helpers.showErrorToast(error.toString())
                }
            }).finally(() => {
                this.hideLoadBar()
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.dd-item {
  transition: all 1s
}

.sortable-ghost {
  border: 1px solid green !important;
  background: #d1eed1;
}
</style>
